<template>
  <v-container id="edit-field-dialog">
    <v-card style="overflow-y: auto; max-height: 90vh">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">{{ FieldName }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="save()" ref="form">
          <v-textarea
            v-model="field[keys.find((x) => x == `pt`)]"
            readonly
            outlined
            :label="keys.find((x) => x == `pt`).toUpperCase()"
            class="my-2"
          >
          </v-textarea>
          <v-textarea
            v-for="(k, i) in keys.filter((x) => x != `pt`)"
            :key="i"
            v-model="field[k]"
            :disabled="k == `pt`"
            outlined
            :label="k.toUpperCase()"
            class="my-2"
          >
          </v-textarea>
          <v-form
            @submit.stop.prevent="add_translation()"
            ref="newTranslationForm"
            v-model="newTranslationValid"
            :lazy-validation="newTranslationLazy"
          >
            <v-row align="center" justify="center">
              <v-col cols="4">
                <v-text-field
                  solo
                  dense
                  outlined
                  v-model="newTranslation"
                  :rules="[two_characters_required, translation_exists]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn text class="mb-7" type="submit">
                  <v-row align="center">
                    <span style="text-transform: none"
                      ><b>{{ $t("add_new_translation") }}</b></span
                    >
                    <v-icon color="primary" class="ml-2">mdi-plus</v-icon>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions class="pa-2 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-alert class="ma-2" v-if="error" type="error">
          {{ $t(error) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "EditFieldDialog",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    error: false,
    field: null,
    alert: null,
    newTranslationValid: true,
    newTranslationLazy: false,
    valid: true,
    lazy: false,
    newTranslation: "",
    keys: [],
    loading: false,
  }),
  async created() {
    this.initialize_field();
  },
  props: {
    Field: String,
    FieldName: String,
  },
  computed: {
    two_characters_required() {
      return (value) => value.length == 2 || this.$t("new_translation_rule");
    },
    translation_exists() {
      return (value) =>
        !this.keys.includes(value.toLowerCase()) ||
        this.$t("translation_already_exists");
    },
  },
  methods: {
    save() {
      var result = this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.$emit("save", this.field);
    },
    add_translation() {
      var result = this.$refs.newTranslationForm.validate();
      if (!result) {
        return;
      }
      this.newTranslation = this.newTranslation.toLowerCase();
      this.keys.push(this.newTranslation);
      this.field[this.newTranslation] = "";
      this.newTranslation = "";
    },
    initialize_field() {
      if (this.gs.isJson(this.Field) && JSON.parse(this.Field).pt != null) {
        this.field = JSON.parse(this.Field);
      } else {
        this.field = {
          pt: this.Field,
          en: this.Field,
        };
      }
      this.keys = [];
      for (let key in this.field) {
        this.keys.push(key);
      }
    },
  },
};
</script>
