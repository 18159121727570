<template>
  <v-container fluid tag="section" v-if="!loading">
    <base-material-card inline color="secondary" class="px-5 py-3 mb-5">
      <template v-slot:heading><v-icon large>mdi-translate</v-icon></template>
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("translation", 2) }}</b>
        </h1></template
      >
      <p style="font-size: 17px; font-weight: 600; margin-top: 16px">
        {{ $t("translation_obs_header") }}
      </p>
      <p class="mb-4" v-for="t in 3" :key="t">
        <b>{{ `${t}. ` }}</b
        >{{ $t(`translation_obs_${t}`) }}
      </p>
      <v-divider class="mb-7"></v-divider>
      <v-form
        @submit.stop.prevent="get_translation_fields()"
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <v-row>
          <v-col cols="5">
            <v-select
              :items="options"
              item-text="Name"
              :rules="[required]"
              solo
              outlined
              v-model="optionSelected"
              @change="get_briefing_options(optionSelected.Briefings)"
              :hint="$t('translation_company_hint')"
              return-object
            >
            </v-select>
          </v-col>
          <v-col cols="5">
            <v-select
              :disabled="
                !optionSelected.Briefings || optionSelected.Briefings.length < 2
              "
              :items="briefingOptions"
              solo
              :rules="[required]"
              outlined
              :hint="$t('translation_briefing_hint')"
              item-text="Text"
              v-model="briefingSelected"
              return-object
            >
            </v-select>
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" type="submit" height="56">
              <span style="font-size: 14px"
                ><b>{{ $t("select") }}</b></span
              >
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
      </v-form>
      <v-row
        align="center"
        class="mt-5"
        v-if="(briefing || fields.length > 0) && !fieldsLoading"
      >
        <h1 class="h5 ml-2">
          <b> {{ optionSelected.Name }}</b
          ><b v-if="briefing">
            - {{ briefing.IsBriefing ? $t("briefing") : $t("profile") }}</b
          >
        </h1>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          height="30"
          :loading="saveLoading"
          @click="translate_briefing"
        >
          <span style="font-size: 13px"
            ><b>{{ $t("save") }}</b></span
          >
        </v-btn>
      </v-row>
      <v-simple-table v-if="(briefing || fields.length > 0) && !fieldsLoading">
        <thead>
          <tr>
            <th class="text-center">{{ $t("field") }}</th>
            <th class="text-center">{{ $t("edit") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(f, index) in fields" :key="index">
            <td class="text-center">{{ f.label }}</td>
            <td class="text-center">
              <v-btn
                class="secondary"
                @click="edit_field(f)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row class="my-6" justify="center" v-else-if="fieldsLoading">
        <v-progress-circular
          indeterminate
          size="70"
          color="primary"
        ></v-progress-circular>
      </v-row>
    </base-material-card>
    <v-dialog
      max-width="650px"
      v-model="editFieldDialog"
      v-if="editFieldDialog"
    >
      <EditFieldDialog
        :Field="fieldValue"
        :FieldName="fieldName"
        @close="editFieldDialog = false"
        @save="save_field"
      />
    </v-dialog>
  </v-container>
  <v-row justify="center" v-else>
    <v-progress-circular
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-row>
</template>

<script>
import ApiService from "@/services/ApiService";
import EditFieldDialog from "./EditFieldDialog/EditFieldDialog";
import moment from "moment";

export default {
  name: "Translation",

  components: {
    EditFieldDialog,
  },

  data: () => ({
    apiService: new ApiService(),
    options: [],
    valid: true,
    lazy: false,
    briefing: null,
    fieldSelected: null,
    fieldValue: null,
    fieldName: null,
    optionSelected: {},
    generalFieldsTranslations: [],
    fields: [],
    briefingOptions: [],
    briefingSelected: null,
    editFieldDialog: false,
    loading: false,
    saveLoading: false,
    fieldsLoading: false,
  }),
  async created() {
    this.get_translation_options();
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    briefing_fields() {
      return [
        {
          label: this.$t("description"),
          field: "Description",
        },
        {
          label: this.$t("financial_overview"),
          field: "FinancialOverview",
        },
        {
          label: `${this.$t("investment_conditions")} - ${this.$t(
            "introduction"
          )}`,
          field: "InvestmentConditionsIntro",
        },
        {
          label: `${this.$t("investment_conditions")} - ${this.$t("text")}`,
          field: "InvestmentConditionsText",
        },
      ];
    },
  },
  methods: {
    get_briefing_options(briefings) {
      var options = [];
      if (briefings.length == 1) {
        this.briefingOptions = briefings;
        this.briefingSelected = briefings[0];
        return;
      }
      for (var i = 0; i < briefings.length; i++) {
        options.push({
          Id: briefings[i].Id,
          Text: briefings[i].IsBriefing
            ? this.$t("briefing")
            : this.$t("profile"),
        });
      }
      this.briefingOptions = options;
      this.briefingSelected = options[0];
    },
    save_field(updatedField) {
      if (this.briefing) {
        if (this.fieldSelected.index != null) {
          this.briefing[this.fieldSelected.field][this.fieldSelected.index][
            this.fieldSelected.subField
          ] = JSON.stringify(updatedField);
        } else {
          this.briefing[this.fieldSelected.field] = JSON.stringify(
            updatedField
          );
        }
      } else {
        let lang = localStorage.getItem("lang");
        this.fieldSelected.label = updatedField[lang];
        for (var i = 0; i < this.generalFieldsTranslations.length; i++) {
          this.generalFieldsTranslations[i].Translation[
            this.fieldSelected.field
          ] = updatedField[this.generalFieldsTranslations[i].Language];
          delete updatedField[this.generalFieldsTranslations[i].Language];
        }
        for (let key in updatedField) {
          var newLang = {
            Language: key,
            Translation: {},
          };
          newLang.Translation[this.fieldSelected.field] = updatedField[key];
          this.generalFieldsTranslations.push(newLang);
        }
      }

      this.editFieldDialog = false;
    },
    edit_field(field) {
      if (this.briefing) {
        this.edit_briefing_field(field);
      } else {
        this.edit_general_field(field);
      }
      this.fieldSelected = field;
      this.fieldName = field.label;
      this.editFieldDialog = true;
    },
    edit_briefing_field(field) {
      if (field.index != null) {
        this.fieldValue = this.briefing[field.field][field.index][
          field.subField
        ];
      } else {
        this.fieldValue = this.briefing[field.field];
      }
    },
    edit_general_field(field) {
      this.fieldValue = {};
      for (var i = 0; i < this.generalFieldsTranslations.length; i++) {
        if (this.generalFieldsTranslations[i].Translation[field.field]) {
          this.fieldValue[
            this.generalFieldsTranslations[i].Language
          ] = this.generalFieldsTranslations[i].Translation[field.field];
        } else {
          this.fieldValue[this.generalFieldsTranslations[i].Language] = "";
        }
      }
      this.fieldValue = JSON.stringify(this.fieldValue);
    },
    get_translation_options() {
      this.loading = true;
      this.apiService
        .getRequest("translation/options")
        .then((resp) => {
          this.options = [
            {
              Name: this.$t("general_fields"),
              CompanyId: null,
              Briefings: [{ Text: "-" }],
            },
          ].concat(JSON.parse(resp.message));
          this.optionSelected = this.options[0];
          this.get_briefing_options(this.optionSelected.Briefings);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    get_translation_fields() {
      var result = this.$refs.form.validate();
      if (!result || this.fieldsLoading) {
        return;
      }
      this.fieldsLoading = true;
      if (this.optionSelected.Briefings.length >= 1) {
        this.apiService
          .getRequest(`translation/all-general-fields`)
          .then((resp) => {
            this.briefing = null;
            var translations = JSON.parse(resp.message);
            for (var i = 0; i < translations.length; i++) {
              translations[i].Translation = JSON.parse(
                translations[i].Translation
              );
            }

            this.generalFieldsTranslations = translations;
            this.fields = [];
            let lang = localStorage.getItem("lang");
            var currentLang = translations.filter((x) => x.Language == lang)[0]
              .Translation;
            for (let key in currentLang) {
              this.fields.push({
                label: currentLang[key],
                field: key,
              });
            }
            this.fields.sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            );
            this.fieldsLoading = false;
          })
          .catch((error) => {
            this.fieldsLoading = false;
          });
      } else {
        this.apiService
          .getRequest(
            `translation/briefing-translatable-fields/${this.briefingSelected.Id}`
          )
          .then((resp) => {
            this.briefing = JSON.parse(resp.message);
            this.fields = this.briefing_fields.filter(
              (x) => this.briefing[x.field] != null
            );
            for (var i = 0; i < this.briefing.CompanyAnalysis.length; i++) {
              this.fields.push({
                label: `${this.$t("company_analysis")} - ${this.$t("text")}`,
                field: "CompanyAnalysis",
                subField: "Text",
                index: i,
              });
              this.fields.push({
                label: `${this.$t("company_analysis")} - ${this.$t("title")}`,
                field: "CompanyAnalysis",
                subField: "Title",
                index: i,
              });
            }
            for (var i = 0; i < this.briefing.CompanyHighlights.length; i++) {
              this.fields.push({
                label: `${this.$t("highlights")}`,
                field: "CompanyHighlights",
                subField: "Text",
                index: i,
              });
            }
            for (var i = 0; i < this.briefing.Team.length; i++) {
              this.fields.push({
                label: `${this.$t("team")} - ${this.$t("member_position")}`,
                field: "Team",
                subField: "Position",
                index: i,
              });
            }
            this.fieldsLoading = false;
          })
          .catch((error) => {
            this.fieldsLoading = false;
          });
      }
    },
    translate_briefing() {
      this.saveLoading = true;
      if (this.briefing) {
        this.apiService
          .postRequest(`translation/translate-briefing`, this.briefing)
          .then((resp) => {
            this.$toast.success(this.$t("translations_saved"));
            this.saveLoading = false;
          })
          .catch((error) => {
            this.saveLoading = false;
          });
      } else {
        var req = [];
        for (var i = 0; i < this.generalFieldsTranslations.length; i++) {
          req.push({
            Language: this.generalFieldsTranslations[i].Language,
            Translation: JSON.stringify(
              this.generalFieldsTranslations[i].Translation
            ),
          });
        }
        let lang = localStorage.getItem("lang");
        this.apiService
          .postRequest(`translation/translate-general-fields`, req)
          .then((resp) => {
            this.$i18n.setLocaleMessage(
              lang,
              this.generalFieldsTranslations.filter(
                (x) => x.Language == lang
              )[0].Translation
            );
            this.$toast.success(this.$t("translations_saved"));
            this.saveLoading = false;
          })
          .catch((error) => {
            this.saveLoading = false;
          });
      }
    },
  },
};
</script>
